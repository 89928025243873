import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "a-great-bluetooth-waterproof-speaker-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Preamble`}</h1>
    <p>{`I had a need for a 'new' speaker. `}</p>
    <p>{`Spoiler: The Aomais GO bluetooth waterproof speaker is a great speaker. It met my requirements and I am very happy with the value/cost ratio. But it isn't perfect.`}</p>
    <h2>{`What MY criteria were for choosing`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`I needed a speaker that had enough volume to broadcast to a group of a dozen people spread out in a large room following COVID-19 protocols in the 2020.10 time period. (I think at this time, most countries have basically the same rules for gatherings.) I had decided, using a dbSPL meter that 90 decibels would be the correct volume.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I wanted to be able to take it 'to the beach' for presentations done there. (Yes I have a wonderful job, especially for someone generally considered a 'computer guy' since 1980.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I didn't want to spend a lot of money if I didn't have to, but I was willing to pay up to $300 Canadian`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I wanted it to be able to play good music, again think corporate events`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I didn't care about 'stereo' or 'surround' sound.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I cared about GOOD quality sound from bass to treble, but I didn't really care about it being as good as my Harmon Karden level system from 1979.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I did want to be able to get to that 90 dbSPL without distortion that my ears could hear; I used to care a lot about high quality systems, ran sound for decades for 2 churches, was a 'emergency' guy for running sound for 2 Christian Rock bands in the 1980's and I hate the sound of 'clipping' or 'hiss', 2 types of distortion.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I wanted speakers that could reliably work at least 8 hours a day, charging each night acceptable.`}</p>
      </li>
    </ul>
    <h2>{`What I decided to buy`}</h2>
    <p>{`I decided on the Aomais GO. It was $85 Canadian at Amazon, less than 1/3rd my budget. `}</p>
    <p>{`The reviews were good.`}</p>
    <p>{`I choose the solid black. It comes it two color with blue and orange at the time I ordered it. But this was for boring business (yes, even on the beach), so the solid black seemed to me to give the right look.`}</p>
    <h2>{`First impressions`}</h2>
    <p>{`But read the second impressions...`}</p>
    <h3>{`first impression positives`}</h3>
    <p>{`Pretty good volume, about what I expected given the 'ratings'.`}</p>
    <p>{`Sound a little 'muddled' compared to my 'cheap' ($200) Logitech speakers.`}</p>
    <p>{`MUCH better in volume and better in quality than any laptop I remember (I've never bought a laptop based on a speaker rating)`}</p>
    <p>{`UI a little funny - it installed as 2 devices, not one. The volume control on the COMPUTER is difficult, it keeps jumping back to the 'previous' value. But if I'm insistent, it finally says, "OK fine, whatever" and goes to the new volume setting, but then nothing seems to change.`}</p>
    <p>{`Ranking quality by range: Bass is better than treble`}</p>
    <p>{`As a 'really good' speaker for my criteria, and actually for any general criteria, I'm impressed.`}</p>
    <p>{`At the PRICE I'm impressed. It is 90% as good as ones I've bought that were double the price (they give gut feel, not technical rating) 10% more volume before distortion than the Aomais GO`}</p>
    <p>{`The solid black works for me visually. It basically won't be noticed, and that is it's job: Provide the sound I need without being noticed.`}</p>
    <h3>{`first impression minor negatives`}</h3>
    <p>{`The biggest irritant is that they don't use a modern C USB port for charging, they use one of the older plugs that you insert the wrong way 8 times out of 9. In this day and age - why are they using such a stupid design when they could be using USB C? And you can't put an adaptor in because you lose the waterproofing. I did say these are 'minor' negatives, but those older "one way only to insert ports" is such a user hostile UX that I don't understand why they decided to do this to their users.`}</p>
    <p>{`The buttons are hard to read in a dim room, think a presentation with the lights turned down, fortunately there is enough tactile feel that if you memorize the order of the 4 buttons and what they do, you can easily 'feel' to find them.`}</p>
    <p>{`There is an annoying bright blue flashing light, again, think a darkened room for presentation. But a piece of black electricians tape as a flap 'fixes' that.`}</p>
    <p>{`The charging notification is a little annoying. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If the cord is plugged in and it is charging, there is a red light, I like this part.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the cord gets unplugged, the red light goes out, if it wasn't for the next, I would like this part.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the speaker is charged, there is a red light, I do not like this part.`}</p>
      </li>
    </ul>
    <p>{`I would prefer to have a green light when it is plugged in but fully charged. I have never been a fan of `}<a parentName="p" {...{
        "href": "/personal/nak/"
      }}>{`NAK`}</a>{` I've been advising people against and fighting against NAK since 1969. Yes I'm that old, but my wife and I have 4 adult kids and, as of 2020, a 8,7,5 and 5 year old, so they keep me young!`}</p>
    <h2>{`Second impressions`}</h2>
    <p>{`Remember ... it's never too late to make a first impression.`}</p>
    <h3>{`2nd impression positive`}</h3>
    <p>{`There was a "how to use this device" sticker over one of the 2 tweeters. (2 tweeters, 2 bass speakers) When I remove that sticker, the sound is significantly less 'muddy' ... go figure. It probably doesn't sound as good when it is under water either, I'll have to try that. ;)`}</p>
    <p>{`So, my first impression feeling went up quite a bit - at it was already good. I retroactively adjusted the first impression in my permanent storage.`}</p>
    <h3>{`2nd impression negative`}</h3>
    <p>{`There is no indication, when charging, about how fully the battery is. If the battery was completely drained, I guess I would know how many hours to a full charge, but if partially ... no indication. I don't know how hard it is, but all of my Rigid batteries tell me that info, and lots of others do with methods like "blink pause blink pause blink" means it is very low "blink blink blink blink blink pause" means it is almost full (with # of blinks in between indicating how charged.) But the Aomais GO doesn't give any indication other than "charging" or "not charging either because it is unplugged or fully charged ... you guess"`}</p>
    <p>{`There are only 15 settings for volume. They are biased toward the LOUD side. Even with the computer set on 6 out of 100, I can get the volume loud enough to have distortion (clipping tops of the waves) but the lowest volume is TOO LOUD for 'office background' etc.., purposes.`}</p>
    <p>{`Going outside of the parameters I had for buying this:`}</p>
    <p>{`I would NOT buy this as a speaker for our younger children in their bedrooms. If it was set to the LOWEST volume was in the kids bedroom, I think my wife would be saying "TURN THAT DOWN' from the family room. Not because it is 'really' loud, but because she doesn't like to HEAR any sound from eDevices used in the bedrooms.`}</p>
    <p>{`In general I have three volumes that I have speakers playing at for personal listening at work or home:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Quiet background, so called white noise, enough that my tinnitus doesn't bother me. This speaker is unable to get that quiet in any way I have found.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Normal playing volume where music is my #1 task (anything else I'm doing being secondary.) This speaker is great for that.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`High volume, for a few favorite songs, and, there are times that I find having 'loud familiar' music allows me, in stressful situations, to concentrate on something specific. In this case I tend to 'suddenly' need to go from high volume to very low #1 level volume when the high volume suddenly becomes distracting.`}</p>
      </li>
    </ol>
    <p>{`And in any event, this speaker is significantly louder than I would EVER use as 'go to sleep' volume and it is significantly louder than I would want as my normal 'office' background volume.`}</p>
    <h2>{`Third and ongoing impressions:`}</h2>
    <h3>{`ongoing impressions, Neutral`}</h3>
    <p>{`It needs about 9 seconds, at least with my high end Asus ZenBook laptop, from the time you turn on the speaker until the time it is paired and playing sound from the computer.`}</p>
    <p>{`There is very hard to read, very small print on the label that I removed prior to the second impressions. It warns that you need to `}<em parentName="p">{`make sure you close the cover over the ports before you put it in water, otherwise you may destroy it`}</em>{`. So take this as your first (or second) warning.`}</p>
    <p>{`Checking the battery life I `}</p>
    <h3>{`ongoing impressions, positive`}</h3>
    <p>{`When we put it on the sound meter, it turns out that, without noticeable distortion, it is 2 dbSPL `}<em parentName="p">{`higher`}</em>{` than the $200 Logitech speakers. While 'noticeable' is obviously subjective, it was done with the same meter, same person, several times up and down, so I consider it reliable enough for a review like this and for life in general.`}</p>
    <p>{`Assuming 500 charge cycles at 40 hours, and assuming I only use it an average of 4 hours a day, that means it should last 5000 days, or 'over 13 years', and because that 500 charge cycles doesn't mean it is really dead it just won't last the full 40 hours, this battery may realistically last me 30 years; That puts me at 90 years old: If I still need it for business then, I think I can justify replacing after that much time. I do suspect though that, due to laws not allowing lead in solder anymore, that the poorer quality solders used these days, that I'm told tend to 'dry out and fail', mean it will likely die before the battery wears out.`}</p>
    <p>{`First test: `}</p>
    <ul>
      <li parentName="ul">{`Day 1: Turned on at 8:06am, played about 4 hours of music at a low volume. Turned it off at 3 minutes after midnight. 16 hours including about 4 with music playing.`}</li>
      <li parentName="ul">{`Day 2: Turned on at 9:08am, played about 5 hours of music at low volume. Noticed at 1:30am that it was still on. 16.5 hours, 32 so far, including about 5, 9 total with music playing. Then somewhere between 0 and 6 hours of being on without playing.`}</li>
      <li parentName="ul">{`Day 3: Noticed at 8:17am that it had turned off. Turned it on. There was a red blinking light. I'm guessing it means 'low power'. But I set music on at low volume. It powered off at 8:39, 22 minutes later.`}</li>
    </ul>
    <p>{`Second test:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Day 1: 8:40am started to charge, red light solid. Somewhere between 12:30 and 1:37 it finished charging, so 4-5 hours for a full charge. Turned it on and started it playing. Interestingly, it started out medium volume, then, about 7 seconds later, went back to the volume I had previously set. Left it on, but stopped playing music at 10:20pm by moving it out of range of the blue tooth, at 2:00am I moved it back, it didn't reconnect. I turned it off and back on, some 7 seconds of medium loud volume, then back to the volume I had it set at. I'm beginning to understand why I initially THOUGHT I had control over the volume using the computer controls - but then it seemed I didn't. Turned the music off, but left speakers 'on' at 3:00am, `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Day 2: turned music back a bit before 10:00am. 40 hours should be 4am day 3. But somewhere between 4pm and 7pm, it ran out of power.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`how did it work when I dunked it in the hot tub? Immediately after, while still wet?`}</li>
    </ul>
    <h3>{`ongoing impressions, negative`}</h3>
    <p>{`No place to store the USB charge cord.`}</p>
    <h1>{`final conclusion`}</h1>
    <p>{`You may think from all the negatives that I don't like this. But I would buy it again without second thought (I DID have second thought the 1st time around, while waiting for it to arrive!) for the same purpose. While there are quite a few 'minor' negatives, you have to realize, this was a really cheap device for what it does, so the fact it does a really good job at the basics for that price is impressive. Most of the fixes I am proposing can probably be made in future versions with just a firmware (software) upgrade, but I doubt Aomais even knows about this page.`}</p>
    <h1>{`The "not required" notice:`}</h1>
    <p>{`Note I make NOTHING from you following my advice. These are all my opinions and no one paid me to post them. I put them up because I like to help other people.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      